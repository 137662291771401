<template>
  <div class="main-card card mb-4">
    <div class="card-header">
      <h2>
        {{ inverterName }} Olay Kayıtları
        <a @click="showAlarmList = !showAlarmList">
          <i
            v-if="showAlarmList"
            class="fas fa-chevron-down card-toggle-button"
          ></i>
          <i v-else class="fas fa-chevron-right card-toggle-button"></i>
        </a>
      </h2>
    </div>
    <div class="card-body py-4" v-show="showAlarmList">
      <div class="row">
        <div class="col-12">
          <div class="report-title-group my-4">
            <div class="report-title">
              <h2>Tablo</h2>
            </div>
            <div class="report-date">
              <date-picker
                :editable="false"
                v-model="selectedDate"
                valueType="format"
                lang="tr"
                format="DD.MM.YYYY"
                @change="dateChange()"
                @pick="datePick"
                type="day"
                :default-value="defaultDate"
                :disabled-date="disabledDate"
                range
              >
              </date-picker>
            </div>
          </div>
          <div class="report-body">
            <div class="report-loading card py-3 mx-auto" v-if="reportLoading">
              <h3>
                Tablonuz Oluşturuluyor <br />Lütfen Bekleyiniz
                <i class="fas fa-spinner fa-pulse text-white"></i>
              </h3>
            </div>
            <inverter-alarm-list-table
              v-show="!reportLoading"
              :inverter-alarms="inverterAlarms"
              :file-name="
                inverterName +
                ' ' +
                selectedDate[0] +
                '-' +
                selectedDate[1] +
                ' Olay Kayıtları'
              "
            ></inverter-alarm-list-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-date {
  width: 250px;
}
</style>

<script>
//Deneme
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/tr";
import "vue2-datepicker/index.css";

import moment from "moment/dist/moment";
moment().locale();
import { GET_INVERTER_ALARMS } from "@/core/services/store/inverters.module";
import InverterAlarmListTable from "./InverterAlarmListTable.vue";

export default {
  components: { DatePicker, InverterAlarmListTable },
  name: "inverter_alarm_list_card",
  props: {
    inverterName: {
      required: true,
    },
  },
  data() {
    return {
      showAlarmList: true, // Kart Göster&Gizle
      selectedDate: [
        moment().subtract(7, "days").format("DD.MM.YYYY"),
        moment().format("DD.MM.YYYY"),
      ],
      defaultDate: [
        moment().subtract(7, "days").format("DD.MM.YYYY"),
        moment().format("DD.MM.YYYY"),
      ],
      reportLoading: true,
      inverterAlarms: null,
      disabledBegin: null,
      disabledToggle: true,
    };
  },
  methods: {
    dateChange() {
      this.getInverterAlarms();
      this.disabledBegin = null;
    },
    datePick(date) {
      if (this.disabledToggle) {
        this.disabledBegin = moment(date).format("YYYY-MM-DD");
      }
      this.disabledToggle = !this.disabledToggle;
    },
    disabledDate(date) {
      return (
        date > moment() || date > moment(this.disabledBegin).add(30, "days") || date < moment(this.disabledBegin).subtract(30, "days")
      );
    },
    getInverterAlarms() {
      this.reportLoading = true;
      this.$store
        .dispatch(GET_INVERTER_ALARMS, {
          id: this.inverterName,
          begin_date: this.selectedDate[0],
          end_date: this.selectedDate[1],
        })
        .then((res) => {
          this.inverterAlarms = res;
          this.reportLoading = false;
        });
    },
  },
  created() {
    this.getInverterAlarms();
  },
};
</script>
