<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card p-4">
        <table
          id="inverterDataTable"
          class="table table-sm table-striped table-bordered table-hover w-100"
        >
          <thead>
            <tr>
              <th class="text-nowrap text-center" rowspan="2">#</th>
              <th class="text-nowrap text-center" rowspan="2">Tarih</th>
              <th class="text-nowrap text-center" rowspan="2">
                Toplam Üretilen Enerji
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                Günlük Üretilen Enerji
              </th>
              <th class="text-nowrap text-center" rowspan="2">Toplam DC Güç</th>
              <th class="text-nowrap text-center" rowspan="2">Toplam AC Güç</th>
              <th class="text-nowrap text-center" rowspan="2">DC Akım</th>
              <th class="text-nowrap text-center" :colspan="max_dc_voltage">
                DC Gerilim
              </th>
              <th class="text-nowrap text-center" rowspan="2">L1 Faz Akım</th>
              <th class="text-nowrap text-center" rowspan="2">L2 Faz Akım</th>
              <th class="text-nowrap text-center" rowspan="2">L3 Faz Akım</th>
              <th class="text-nowrap text-center" rowspan="2">
                Toplam AC Akım
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                L1 Fazı Gerilim
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                L2 Fazı Gerilim
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                L3 Fazı Gerilim
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                L1 Fazı Aktif Güç
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                L2 Fazı Aktif Güç
              </th>
              <th class="text-nowrap text-center" rowspan="2">
                L3 Fazı Aktif Güç
              </th>
              <th class="text-nowrap text-center" rowspan="2">Reaktif Güç</th>
              <th class="text-nowrap text-center" rowspan="2">Güç Faktörü</th>
              <th class="text-nowrap text-center" rowspan="2">Frekans</th>
            </tr>
            <tr>
              <th
                is="th"
                class="text-center"
                v-for="index in max_dc_voltage"
                :key="index"
              >
                {{ String.fromCharCode(64 + index) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in inverterData" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td class="text-nowrap text-center" :data-order="item.timestamp">
                {{ item.dateConvert }}
              </td>
              <td class="text-right" :data-order="item.ac_total_energy">
                {{ item.ac_total_energy_convert }}
              </td>
              <td class="text-right" :data-order="item.ac_daily_energy">
                {{ item.ac_daily_energy_convert }}
              </td>
              <td
                class="text-nowrap text-right"
                :data-order="item.dc_power_total"
              >
                {{ item.dc_power_total_convert }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <i class="far fa-comment text-secondary mx-2"></i>
                    </span>
                  </template>
                  <span
                    v-for="(item, index) in item.dc_power_arr"
                    :key="item.id"
                  >
                    [{{ String.fromCharCode(65 + index) }}] {{ item }} <br />
                  </span>
                </v-tooltip>
              </td>
              <td class="text-right">
                {{ item.ac_active_power_total_convert }}
              </td>
              <td
                class="text-nowrap text-right"
                :data-order="item.dc_current_total"
              >
                {{ item.dc_current_total }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <i class="far fa-comment text-secondary mx-2"></i>
                    </span>
                  </template>
                  <span
                    v-for="(item, index) in item.dc_current_arr"
                    :key="item.id"
                  >
                    [{{ String.fromCharCode(65 + index) }}] {{ item }} <br />
                  </span>
                </v-tooltip>
              </td>
              <td
                is="td"
                class="text-right"
                v-for="index in max_dc_voltage"
                :key="index"
              >
                {{
                  item.dc_voltage_array[(index-1)] != null
                    ? item.dc_voltage_array[(index-1)]
                    : "---"
                }}
              </td>
              <td class="text-right">{{ item.ac_current_L1 }}</td>
              <td class="text-right">{{ item.ac_current_L2 }}</td>
              <td class="text-right">{{ item.ac_current_L3 }}</td>
              <td class="text-right">{{ item.ac_current_total }}</td>
              <td class="text-right">{{ item.ac_voltage_L1 }}</td>
              <td class="text-right">{{ item.ac_voltage_L2 }}</td>
              <td class="text-right">{{ item.ac_voltage_L3 }}</td>
              <td class="text-right">{{ item.ac_active_power_L1 }}</td>
              <td class="text-right">{{ item.ac_active_power_L2 }}</td>
              <td class="text-right">{{ item.ac_active_power_L3 }}</td>
              <td class="text-right">{{ item.ac_reactive_power }}</td>
              <td class="text-right">{{ item.ac_power_factor }}</td>
              <td class="text-right">{{ item.ac_frequency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fa-comment {
  &:hover {
    color: #00bcd4 !important;
  }
}
</style>

<script>
import "@/assets/js/functions/datatables.settings";
import $ from "jquery";

export default {
  name: "inverter_data_table",
  data() {
    return {
      table: $("#inverterDataTable").DataTable(),
      max_dc_voltage: 1,
    };
  },
  props: ["inverterData", "fileName"],
  methods: {
    updateTable() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = $("#inverterDataTable").DataTable({
          columnDefs: [{ type: "num", targets: [0, 1] }],
          buttons: [
            {
              extend: "colvis",
              className: "btn-sm btn-primary mr-1",
              text: "Göster&Gizle",
            },
            {
              extend: "excel",
              className: "btn-sm btn-danger",
              title: this.fileName,
              filename: this.fileName,
            },
          ],
        });
      });
    },
  },
  watch: {
    inverterData(data) {
      this.max_dc_voltage = 1;
      data.forEach((item) => {
        let temp = item.dc_voltage_array.length;
        if (temp > this.max_dc_voltage) {
          this.max_dc_voltage = temp;
        }
      });
      this.updateTable();
    },
  },
};
</script>
