<template>
  <div class="row">
    <div class="col-12">
      <highcharts :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import { wattConvertor } from "@/assets/js/functions/wattConvertor";

import "@/assets/js/functions/highcharts.settings";

import moment from "moment/dist/moment";
import "@/assets/js/functions/moment.settings";

export default {
  name: "inverter_data_graph",
  props: {
    inverterData: {
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {},
      seriesData: [],
      total: 0,
      dc_power_total_series: [],
      ac_power_total_series: [],
      dc_current_total_series: [],
      ac_current_L1_series: [],
      ac_current_L2_series: [],
      ac_current_L3_series: [],
      ac_current_total_series: [],
      ac_voltage_L1_series: [],
      ac_voltage_L2_series: [],
      ac_voltage_L3_series: [],
      ac_active_power_L1_series: [],
      ac_active_power_L2_series: [],
      ac_active_power_L3_series: [],
      ac_reactive_power_series: [],
      ac_power_factor_series: [],
      ac_frequency_series: [],
      max_dc_voltage: 1,
      dc_voltage_series: [],
    };
  },
  computed: {
    totalConvert() {
      return wattConvertor(this.total, "h");
    },
  },
  methods: {
    getVisibleSeries(series, returnDefault) {
      if (localStorage.getItem("inverterData " + series)) {
        return localStorage.getItem("inverterData " + series) === "true";
      } else {
        return returnDefault;
      }
    },
    createChart() {
      this.chartOptions = {
        legend: {
          align: "center",
          verticalAlign: "bottom",
          borderWidth: 1,
        },
        chart: {
          type: "spline",
          zoomType: "x",
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true,
                  radius: 5,
                },
              },
            },
            shadow: false,
            states: {
              hover: {
                lineWidth: 2,
              },
            },
            events: {
              legendItemClick: function () {
                localStorage.setItem(
                  "inverterData " + this.name,
                  !this.visible
                );
              },
            },
          },
        },
        yAxis: {
          title: {
            text: "Değerler",
          },
        },
        xAxis: {
          labels: {
            format: "{value:%H:%M}",
          },
          type: "datetime",
        },
        series: [
          {
            name: "Toplam DC Güç",
            custom: {
              tooltipType: 1,
            },
            data: this.dc_power_total_series,
            visible: this.getVisibleSeries("Toplam DC Güç", true),
          },
          {
            name: "Toplam AC Güç",
            custom: {
              tooltipType: 2,
            },
            data: this.ac_power_total_series,
            visible: this.getVisibleSeries("Toplam AC Güç", true),
          },
          {
            name: "DC Akım",
            custom: {
              tooltipType: 3,
            },
            data: this.dc_current_total_series,
            visible: this.getVisibleSeries("DC Akım", false),
          },
          ...this.dc_voltage_series,
          {
            name: "L1 Faz Akımı",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_current_L1_series,
            visible: this.getVisibleSeries("L1 Faz Akımı", false),
          },
          {
            name: "L2 Faz Akımı",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_current_L2_series,
            visible: this.getVisibleSeries("L2 Faz Akımı", false),
          },
          {
            name: "L3 Faz Akımı",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_current_L3_series,
            visible: this.getVisibleSeries("L3 Faz Akımı", false),
          },
          {
            name: "Toplam AC Akım",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_current_total_series,
            visible: this.getVisibleSeries("Toplam AC Akım", false),
          },
          {
            name: "L1 Fazı Gerilim",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_voltage_L1_series,
            visible: this.getVisibleSeries("L1 Fazı Gerilim", false),
          },
          {
            name: "L2 Fazı Gerilim",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_voltage_L2_series,
            visible: this.getVisibleSeries("L2 Fazı Gerilim", false),
          },
          {
            name: "L3 Fazı Gerilim",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_voltage_L3_series,
            visible: this.getVisibleSeries("L3 Fazı Gerilim", false),
          },
          {
            name: "L1 Fazı Aktif Güç",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_active_power_L1_series,
            visible: this.getVisibleSeries("L1 Fazı Aktif Güç", false),
          },
          {
            name: "L2 Fazı Aktif Güç",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_active_power_L2_series,
            visible: this.getVisibleSeries("L2 Fazı Aktif Güç", false),
          },
          {
            name: "L3 Fazı Aktif Güç",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_active_power_L3_series,
            visible: this.getVisibleSeries("L3 Fazı Aktif Güç", false),
          },
          {
            name: "Reaktif Güç",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_reactive_power_series,
            visible: this.getVisibleSeries("Reaktif Güç", false),
          },
          {
            name: "Güç Faktörü",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_power_factor_series,
            visible: this.getVisibleSeries("Güç Faktörü", false),
          },
          {
            name: "Frekans",
            custom: {
              tooltipType: 0,
            },
            data: this.ac_frequency_series,
            visible: this.getVisibleSeries("Frekans", false),
          },
        ],
        tooltip: {
          formatter: function () {
            var tooltip =
              `<span style="color:` +
              this.series.color +
              `"><b>` +
              this.series.name +
              `</br>Saat:</b> </span>` +
              moment(this.x).format("HH:mm") +
              `<span>`;
            switch (this.series.options.custom.tooltipType) {
              case 0:
                tooltip +=
                  `</span></br><span style="color:` +
                  this.series.color +
                  `"><b>Değer:</b> </span>` +
                  this.y;
                break;
              case 1:
                tooltip +=
                  `</span></br><span style="color:` +
                  this.series.color +
                  `"><b>Toplam:</b> </span>` +
                  wattConvertor(this.y);
                this.point.custom.dc_power_arr.forEach((item, index) => {
                  tooltip +=
                    `</br><span style="color:` +
                    this.series.color +
                    `"><b>` +
                    String.fromCharCode(65 + index) +
                    `:</b> </span>` +
                    wattConvertor(item * 1);
                });
                break;
              case 2:
                tooltip +=
                  `</span></br><span style="color:` +
                  this.series.color +
                  `"><b>Toplam:</b> </span>` +
                  wattConvertor(this.y);
                break;
              case 3:
                tooltip +=
                  `</span></br><span style="color:` +
                  this.series.color +
                  `"><b>Toplam:</b> </span>` +
                  this.y;
                this.point.custom.dc_current_arr.forEach((item, index) => {
                  tooltip +=
                    `</br><span style="color:` +
                    this.series.color +
                    `"><b>` +
                    String.fromCharCode(65 + index) +
                    `:</b> </span>` +
                    item;
                });
                break;
            }
            tooltip += `</span>`;
            return tooltip;
          },
        },
      };
    },
  },
  watch: {
    inverterData(data) {
      //Grafiği Oluşturmadan Önce Verileri Sıfırlama
      this.dc_power_total_series = [];
      this.ac_power_total_series = [];
      this.dc_current_total_series = [];
      this.ac_current_L1_series = [];
      this.ac_current_L2_series = [];
      this.ac_current_L3_series = [];
      this.ac_current_total_series = [];
      this.ac_voltage_L1_series = [];
      this.ac_voltage_L2_series = [];
      this.ac_voltage_L3_series = [];
      this.ac_active_power_L1_series = [];
      this.ac_active_power_L2_series = [];
      this.ac_active_power_L3_series = [];
      this.ac_reactive_power_series = [];
      this.ac_power_factor_series = [];
      this.ac_frequency_series = [];
      this.max_dc_voltage = 1;
      this.dc_voltage_series = [];

      //Highchartsın daha verimli çalışabilmesi için zaman damgalarının
      //küçükten büyüğe doğru sıralı olarak gelmesi gerekiyor
      //invertör verilerinin zaman damgaları büyükten küçüğe doğru geldiğinden diziyi ters çevirdim
      let temp_data = data;
      temp_data = temp_data.slice().reverse();

      temp_data.forEach((item) => {
        //Zaman Damgası
        let time = item.timestampx;

        //Toplam DC Güç
        this.dc_power_total_series.push({
          x: time,
          y: item.dc_power_total,
          custom: {
            dc_power_arr: item.dc_power_arr,
          },
        });

        //Toplam AC Güç
        this.ac_power_total_series.push({
          x: time,
          y: item.ac_active_power_total,
        });

        //Toplam DC Akım
        this.dc_current_total_series.push({
          x: time,
          y: item.dc_current_total * 1,
          custom: {
            dc_current_arr: item.dc_current_arr,
          },
        });

        //L1 Faz Akımı
        this.ac_current_L1_series.push({
          x: time,
          y: item.ac_current_L1 * 1,
        });

        //L2 Faz Akımı
        this.ac_current_L2_series.push({
          x: time,
          y: item.ac_current_L2 * 1,
        });

        //L3 Faz Akımı
        this.ac_current_L3_series.push({
          x: time,
          y: item.ac_current_L3 * 1,
        });

        //Toplam AC Akım
        this.ac_current_total_series.push({
          x: time,
          y: item.ac_current_total * 1,
        });

        //L1 Fazı Gerilim
        this.ac_voltage_L1_series.push({
          x: time,
          y: item.ac_voltage_L1 * 1,
        });

        //L2 Fazı Gerilim
        this.ac_voltage_L2_series.push({
          x: time,
          y: item.ac_voltage_L2 * 1,
        });

        //L3 Fazı Gerilim
        this.ac_voltage_L3_series.push({
          x: time,
          y: item.ac_voltage_L3 * 1,
        });

        //L1 Fazı Aktif Güç
        this.ac_active_power_L1_series.push({
          x: time,
          y: item.ac_active_power_L1 * 1,
        });

        //L2 Fazı Aktif Güç
        this.ac_active_power_L2_series.push({
          x: time,
          y: item.ac_active_power_L2 * 1,
        });

        //L3 Fazı Aktif Güç
        this.ac_active_power_L3_series.push({
          x: time,
          y: item.ac_active_power_L3 * 1,
        });

        //Reaktif Güç
        this.ac_reactive_power_series.push({
          x: time,
          y: item.ac_reactive_power * 1,
        });

        //Güç Faktörü
        this.ac_power_factor_series.push({
          x: time,
          y: item.ac_power_factor * 1,
        });

        //Frekans
        this.ac_frequency_series.push({
          x: time,
          y: item.ac_frequency * 1,
        });

        //DC Akım MAX Kanal Sayısı Bulma
        let temp = item.dc_voltage_array.length;
        if (temp > this.max_dc_voltage) {
          this.max_dc_voltage = temp;
        }
      });

      //DC Akım Verileri
      for (var i = 0; i < this.max_dc_voltage; i++) {
        let temp_name = "DC Gerilim " + String.fromCharCode(65 + i);
        this.dc_voltage_series.push({
          name: temp_name,
          custom: {
            tooltipType: 0,
          },
          data: [],
          visible: this.getVisibleSeries(temp_name, false),
        });
      }
      temp_data.forEach((item) => {
        let time = item.timestampx;
        item.dc_voltage_array.forEach((item, index) => {
          this.dc_voltage_series[index].data.push({
            x: time,
            y: item * 1,
          });
        });
      });
      this.createChart();
    },
  },
};
</script>
