<template>
  <div>
    <today-info
      :info-data="infoData"
      :today-data="getDailyInverterData"
      :total-data="getTotalInverterData"
    ></today-info>

    <hr class="my-5" />

    <inverter-data-card
      v-if="invertersInfo.inverter_id"
      :inverter-name="invertersInfo.inverter_id"
    ></inverter-data-card>

    <hr class="my-5" />

    <inverter-product-card
      v-if="invertersInfo.inverter_id"
      :inverter-name="invertersInfo.inverter_id"
    ></inverter-product-card>

    <hr class="my-5" />

    <inverter-alarm-list-card
      v-if="invertersInfo.inverter_id"
      :inverter-name="invertersInfo.inverter_id"
    ></inverter-alarm-list-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_INVERTER,
  GET_TOTAL_INVERTER_DATA,
  REFRESH_INVERTER_DATAS,
  REFRESH_DAILY_INVERTER_DATA,
  REFRESH_TOTAL_INVERTER_DATA,
} from "@/core/services/store/inverters.module";
import TodayInfo from "../../custom/info/TodayInfo.vue";
import InverterDataCard from "./InverterData/InverterDataCard.vue";
import InverterProductCard from "./InverterProduct/InverterProductCard.vue";

import moment from "moment/moment";
import InverterAlarmListCard from "./InverterAlarmList/InverterAlarmListCard.vue";
moment().locale();

export default {
  name: "inverters.show",
  components: {
    TodayInfo,
    InverterDataCard,
    InverterProductCard,
    InverterAlarmListCard,
  },
  data() {
    return {
      invertersInfo: [],
      infoData: {
        header: ["ID", "Sahası", "Panel Sayısı", "Panel Top. Güç"],
        data: [],
        ready: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getDailyInverterData",
      "getTotalInverterData",
    ]),
    getImage() {
      return process.env.BASE_URL + "media/images/ges1.jpg";
    },
  },
  created() {
    this.$store.dispatch(REFRESH_DAILY_INVERTER_DATA);
    this.$store.dispatch(REFRESH_TOTAL_INVERTER_DATA);
    this.$store.dispatch(REFRESH_INVERTER_DATAS);
    this.$store
      .dispatch(GET_INVERTER, this.$route.params.id)
      .then((res) => {
        this.invertersInfo = res;
        //Detay Verilerini Getirme
        this.infoData.data = [
          res.inverter_id,
          res.station_field_name,
          res.connected_panel_count,
          res.connected_panel_total_power,
        ];
        this.infoData.ready = true;
        return res;
      })
      .then((res) => {
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: "İnvertörler",
            route: "/inverters",
            icon: "flaticon-dashboard",
          },
          { title: res.inverter_id },
        ]);
        this.$store.dispatch(GET_TOTAL_INVERTER_DATA, { id: res.inverter_id });
      });
  },
};
</script>
